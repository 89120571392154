<template>
  <div>
    <a-form-model ref="form" :model="form" v-bind="$formItemLayout4DetailSm">
      <a-form-model-item
        label="名称："
        prop="goodsName"
        :rules="{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }"
      >
        <a-input v-model.trim="form.goodsName" placeholder="请输入内容" :maxLength="25" />
      </a-form-model-item>
      <a-form-model-item
        label="封面："
        prop="coverUrl"
        :rules="{
          required: true,
          message: '请上传封面',
          trigger: 'blur'
        }"
      >
        <image-upload :url="form.coverUrl" @success="handleCoverUrl" @remove="form.coverUrl = ''"></image-upload>
      </a-form-model-item>

      <a-form-model-item
        label="介绍："
        prop="introduction"
        :rules="{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }"
      >
        <a-input
          v-model="form.introduction"
          placeholder="请输入内容"
          :maxLength="500"
          :autoSize="{ minRows: 4, maxRows: 4 }"
          type="textarea"
          @change="() => $refs.form.clearValidate('instance')"
        />
        <div style="position:absolute;bottom:-10px;right:2px;">
          <span style="background:#fff">{{ form.introduction.length }}/500</span>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="服务特色："
        prop="characteristic"
        :rules="{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }"
      >
        <a-input
          v-model="form.characteristic"
          placeholder="请输入内容"
          :maxLength="500"
          :autoSize="{ minRows: 4, maxRows: 4 }"
          type="textarea"
          @change="() => $refs.form.clearValidate('instance')"
        />
        <div style="position:absolute;bottom:-10px;right:2px;">
          <span style="background:#fff">{{ form.characteristic.length }}/500</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="视频介绍" prop="videoUrl">
        <div>
          <single-upload :accept="['mp4']" :limit="1024 * 1024 * 200" @success="handleVideoUrl"></single-upload>

          <a-row v-if="form.videoUrl" style="margin:8px 0;line-height:normal" type="flex" align="middle">
            <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
              <a
                @click="handlePreviewVideo(form.videoUrl)"
                target="blank_"
                style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
              >
                <a-icon type="file" style="margin-right:8px" />{{ form.videoUrl }}
              </a>
            </a-col>
            <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="form.videoUrl = ''">
              <a-icon type="delete" class="error-color" />
            </a-col>
          </a-row>

          <div class="disabled-color" style="line-height:1.5">
            提示：MP4格式文件，大小不超过200MB
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="购买须知："
        prop="purchaseInstructions"
        :rules="{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }"
      >
        <a-input
          v-model="form.purchaseInstructions"
          placeholder="请输入内容"
          :maxLength="300"
          :autoSize="{ minRows: 3, maxRows: 3 }"
          type="textarea"
          @change="() => $refs.form.clearValidate('instance')"
        />
        <div style="position:absolute;bottom:-10px;right:2px;">
          <span style="background:#fff">{{ form.purchaseInstructions.length }}/300</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="商品选择：">
        <a-button type="primary" shape="round" @click="showGoodsModel = true" style="width:100px">添加</a-button>
        <div slot="extra">
          <a-icon
            style="margin-right:4px"
            class="error-color"
            theme="filled"
            type="exclamation-circle"
          />关联服务都为必填项，请确保关联后保存。
        </div>
      </a-form-model-item>
      <template v-if="form.combinationSpecificationsSet.length === 0">
        <a-col style="margin:90px auto 90px auto" type="flex" align="middle">
          <a-row>
            <a-avatar :size="110" shape="square" src="record.url"></a-avatar>
          </a-row>
          <a-row style="margin-top:20px">
            <span class="add-tips">您还没添加任何服务商品</span>
          </a-row>
        </a-col>
      </template>
      <GoodsTable v-show="form.combinationSpecificationsSet.length !== 0" ref="goods" @change="onSpecificationChange" />
      <a-form-model-item
        label="原始售价："
        prop="originPrice"
        :rules="{
          required: true,
          message: '原始售价不能为空',
          trigger: 'blur'
        }"
      >
        <a-input-number
          disabled
          v-model.trim="form.originPrice"
          :min="0"
          :max="999999.99"
          :precision="2"
          :formatter="value => `￥${value}`"
          :parser="value => value.replace('￥', '')"
        />
      </a-form-model-item>
      <a-form-model-item
        label="折扣："
        prop="discount"
        :rules="{
          required: true,
          message: '折扣不能为空',
          trigger: 'blur'
        }"
      >
        <a-input-number
          v-model.trim="form.discount"
          :min="0"
          :max="100"
          :formatter="value => `${value}%`"
          :parser="value => value.replace('%', '')"
        />
      </a-form-model-item>
      <a-form-model-item
        label="实际售价："
        prop="sellPrice"
        :rules="{
          required: true,
          message: '实际售价不能为空',
          trigger: 'blur'
        }"
      >
        <a-input-number
          v-model.trim="form.sellPrice"
          :min="0"
          :max="999999.99"
          :precision="2"
          :formatter="value => `￥${value}`"
          :parser="value => value.replace('￥', '')"
        />
      </a-form-model-item>
    </a-form-model>
    <GoodsModel :show.sync="showGoodsModel" :compareData="compareData" @confirm="onGoodsConfirm" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import GoodsModel from './GoodsModel'
import GoodsTable from './GoodsTable'
import { ImageUpload, SingleUpload } from '@/components/_heath'
import * as goodsserviceApi from '@/api/goodsservice'
import { previewVideo } from '@/utils/util'

export default {
  components: {
    ImageUpload,
    SingleUpload,
    GoodsModel,
    GoodsTable
  },
  data() {
    return {
      showGoodsModel: false,
      form: {
        coverUrl: '',
        discount: 100,
        characteristic: '',
        combinationSpecificationsSet: [],
        goodsName: '',
        originPrice: 0,
        purchaseInstructions: '',
        introduction: '',
        sellPrice: 0,
        videoUrl: ''
      },
      compareData: []
    }
  },
  computed: {
    priceRange() {
      const {
        form: { originPrice = 0, discount = 0 }
      } = this
      return {
        originPrice,
        discount
      }
    }
  },
  watch: {
    priceRange: {
      handler(val) {
        this.form.sellPrice = (val.originPrice * val.discount) / 100
      },
      deep: true
    }
  },
  methods: {
    initData(id) {
      if (id) {
        goodsserviceApi.combinationEdit(id).then(res => {
          const {
            characteristic,
            coverUrl,
            discount,
            goodsName,
            goodsServiceCombinationSpecificationsSet: combinationSpecificationsSet,
            introduction,
            originPrice,
            purchaseInstructions,
            sellPrice,
            videoUrl
          } = res.data
          this.form = Object.assign({}, this.form, {
            characteristic,
            coverUrl,
            discount,
            goodsName,
            combinationSpecificationsSet,
            id,
            introduction,
            originPrice,
            purchaseInstructions,
            sellPrice,
            videoUrl
          })
          this.initSpecificationsTable(combinationSpecificationsSet)
        })
      }
    },
    validate() {
      const {
        $refs: { form },
        form: { combinationSpecificationsSet }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (combinationSpecificationsSet.length < 1) {
            this.$message.info('组合商品至少需要两个单品')
            return reject(err)
          }
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    handleCoverUrl(url) {
      this.form.coverUrl = url
      this.$refs.form.clearValidate('coverUrl')
    },
    // 上传的文件信息
    handleVideoUrl(uploadInfo) {
      this.form.videoUrl = uploadInfo.url
    },
    initSpecificationsTable(record) {
      const goodsData = []
      record.forEach((element, index) => {
        const {
          id: specificationId,
          sellPrice,
          value,
          isValueAddedService,
          goodsName,
          specificationsList: selectOptions,
          classification
        } = element
        goodsData.push({
          id: index,
          goodsName,
          specificationId,
          sellPrice,
          value,
          selectOptions,
          isValueAddedService,
          classification
        })
      })
      const { $refs, $nextTick } = this
      $nextTick(() => {
        $refs.goods.initData(goodsData)
      })
    },
    onGoodsConfirm(record) {
      const goodsData = []
      record.forEach(element => {
        const { id, goodsName, goodsServiceIndividualSpecificationsVOList: selectOptions, classification } = element
        goodsData.push({
          id,
          goodsName,
          specificationId: selectOptions[0].id,
          sellPrice: selectOptions[0].sellPrice,
          value: selectOptions[0].value,
          selectOptions,
          isValueAddedService: false,
          classification
        })
      })

      const { $refs, $nextTick } = this
      $nextTick(() => {
        $refs.goods.initData(goodsData)
      })
    },
    onSpecificationChange(record) {
      const combinationSpecificationsSet = []
      this.compareData = []
      let price = 0
      record.forEach(element => {
        const { specificationId, isValueAddedService, sellPrice, classification } = element
        combinationSpecificationsSet.push({ specificationId, isValueAddedService })
        this.compareData.push(classification)
        price += sellPrice
      })
      this.form.combinationSpecificationsSet = combinationSpecificationsSet
      this.form.originPrice = price
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped>
.service-label {
  white-space: normal;
  word-break: break-all;
}

/deep/ .avatar-uploader-wrap {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-wrap {
  width: 100px;
  height: 100px;
}
</style>
