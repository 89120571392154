<template>
  <div>
    <a-table rowKey="id" :data-source="goodsData">
      <a-table-column key="status" title="序号" align="center" width="80px">
        <template slot-scope="text, record, index">
          <span>{{ index + 1 }}</span>
        </template>
      </a-table-column>
      <a-table-column key="goodsName" title="商品名称" data-index="goodsName" align="center" width="80px" />
      <a-table-column key="specificationId" title="规格" data-index="tags" align="center" width="80px">
        <template slot-scope="text, record, index">
          <template v-if="!record.selectOptions || record.selectOptions.length < 2">
            默认规格
          </template>
          <template v-else>
            <h-select v-model="record.specificationId" @change="onSelectChange(index, $event)">
              <a-select-option v-for="select in record.selectOptions" :key="select.id" :value="select.id">
                {{ select.description }}
              </a-select-option>
            </h-select>
          </template>
        </template>
      </a-table-column>
      <a-table-column key="value" title="值" data-index="value" align="center" width="80px" />
      <a-table-column key="sellPrice" title="售价" data-index="sellPrice" align="center" width="80px" />
      <a-table-column key="isValueAddedService" title="是否为增值服务" align="center" width="80px">
        <template slot-scope="text, record">
          <a-radio-group v-model="record.isValueAddedService">
            <a-radio :value="true">是 </a-radio>
            <a-radio :value="false">否 </a-radio>
          </a-radio-group>
        </template>
      </a-table-column>
      <a-table-column key="operation" title="操作" align="center" width="80px">
        <template slot-scope="text, record">
          <div class="editable-row-operations">
            <a-popconfirm title="确定要删除?" @confirm="() => onDelete(record.id)">
              <a>删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsData: []
    }
  },
  watch: {
    goodsData: {
      handler() {
        this.onEmit()
      },
      deep: true
    }
  },
  methods: {
    initData(record = []) {
      this.goodsData = [...this.goodsData, ...record]
    },
    onDelete(id) {
      const dataSource = [...this.goodsData]
      this.goodsData = dataSource.filter(item => item.id !== id)
      this.onEmit()
    },
    onEmit() {
      const dataSource = [...this.goodsData]
      this.$emit('change', dataSource)
    },
    onSelectChange(index, specificationId) {
      const newData = [...this.goodsData]
      const target = newData[index].selectOptions
      const selected = target.filter(item => specificationId === item.id)[0]
      newData[index].sellPrice = selected.sellPrice
      newData[index].value = selected.value
      this.goodsData = newData
    }
  }
}
</script>

<style lang="less" scoped></style>
