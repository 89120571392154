<template>
  <a-modal
    centered
    v-model="visible"
    :width="700"
    title="关联商品"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0, 'min-height': '400px' }"
    :ok-button-props="{ props: { disabled: !selectedRowKeys.length === 0 } }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 40px' }">
      <a-form-model class="ant-advanced-search-form">
        <a-row :gutter="[24, 0]">
          <a-form-model class="ant-advanced-search-form">
            <a-col :lg="10" :md="12" :sm="24">
              <a-form-model-item label="输入搜索：">
                <a-input-search
                  v-model="query.goodsName"
                  placeholder="请输入商品名称"
                  style="width: 100%"
                  @search="initData"
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="10" :md="12" :sm="24">
              <a-form-model-item label="分类：">
                <h-select v-model="query.classification" allowClear placeholder="全部分类" @change="() => initData()">
                  <a-select-option v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">{{
                    typeInfo.label
                  }}</a-select-option>
                </h-select>
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </a-form-model>
      <a-table
        rowKey="id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onSelect: onSelectChange }"
        :data-source="list"
        :pagination="pagination"
        :loading="list_loading"
        @change="handleTableChange"
      >
        <a-table-column key="coverUrl" title="商品图片" align="center" width="120px">
          <template slot-scope="text, record">
            <a-avatar :size="50" shape="square" :src="record.coverUrl"></a-avatar>
          </template>
        </a-table-column>
        <a-table-column key="goodsName" title="商品名称" align="center" data-index="goodsName" width="100px" />
        <a-table-column key="classification" title="商品分类" align="center" width="100px">
          <template slot-scope="record">
            <span>
              {{ record.classification | ServiceManagerTypeText() }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="specifications" title="规格" align="center" data-index="specifications" />
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import * as goodsserviceApi from '@/api/goodsservice'
import { SERVICE_MANAGER_TYPE } from '@/store/dictionary'
import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      query: {
        classification: undefined,
        goodsName: undefined,
        status: undefined,
        type: 'INDIVIDUAL'
      },
      selectedRowKeys: [],
      selectedData: [],
      list_loading: false,
      list: [],
      pagination: {},
      typeOptions: SERVICE_MANAGER_TYPE
    }
  },
  computed: {
    visible: {
      set: function(val) {
        this.$emit('update:show', val)
      },
      get: function() {
        return this.show
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    compareData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },

  methods: {
    handleOk() {
      this.visible = false
      const { selectedData } = this
      const target = cloneDeep(selectedData)
      this.selectedData = []
      this.selectedRowKeys = []
      this.$emit('confirm', target)
    },
    handleCancel() {
      this.selectedData = []
      this.selectedRowKeys = []
    },
    initData() {
      this.pagination = this.initPagination()
      this.getData()
    },
    getData() {
      this.list_loading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      goodsserviceApi
        .individualList({ pageIndex, pageSize, ...query })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.list_loading = false
        })
    },
    onSelectChange(record) {
      const { selectedRowKeys, selectedData } = this

      if (selectedRowKeys.includes(record.id)) {
        selectedRowKeys.splice(
          selectedRowKeys.findIndex(item => item.id === record.id),
          1
        )
        selectedData.splice(
          selectedData.findIndex(item => item.id === record.id),
          1
        )
        this.selectedRowKeys = selectedRowKeys
        this.selectedData = selectedData

        return
      }
      const doArr1 = this.compareData.filter(item => {
        return record.classification === item
      })
      const doArr2 = this.selectedData.filter(item => {
        return record.classification === item.classification
      })
      if ([...doArr1, ...doArr2].length > 0) {
        this.$message.info('同一分类的单项服务商品只能选择一个')
        return
      }
      this.selectedData.push(record)
      this.selectedRowKeys.push(record.id)
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: false,
        total: 0,
        pageSizeOptions: ['4']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form {
  overflow: hidden;
  /deep/ .ant-form-item {
    display: flex;
    margin-bottom: 0;
  }
  /deep/ .ant-form-item-control-wrapper {
    flex: 1;
  }
}
/deep/ tr th.ant-table-selection-column .ant-table-header-column {
  visibility: hidden;
}
</style>
