var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout4DetailSm,false),[_c('a-form-model-item',{attrs:{"label":"名称：","prop":"goodsName","rules":{
        required: true,
        message: '请输入内容',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":25},model:{value:(_vm.form.goodsName),callback:function ($$v) {_vm.$set(_vm.form, "goodsName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.goodsName"}})],1),_c('a-form-model-item',{attrs:{"label":"封面：","prop":"coverUrl","rules":{
        required: true,
        message: '请上传封面',
        trigger: 'blur'
      }}},[_c('image-upload',{attrs:{"url":_vm.form.coverUrl},on:{"success":_vm.handleCoverUrl,"remove":function($event){_vm.form.coverUrl = ''}}})],1),_c('a-form-model-item',{attrs:{"label":"介绍：","prop":"introduction","rules":{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":500,"autoSize":{ minRows: 4, maxRows: 4 },"type":"textarea"},on:{"change":() => _vm.$refs.form.clearValidate('instance')},model:{value:(_vm.form.introduction),callback:function ($$v) {_vm.$set(_vm.form, "introduction", $$v)},expression:"form.introduction"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-10px","right":"2px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.introduction.length)+"/500")])])],1),_c('a-form-model-item',{attrs:{"label":"服务特色：","prop":"characteristic","rules":{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":500,"autoSize":{ minRows: 4, maxRows: 4 },"type":"textarea"},on:{"change":() => _vm.$refs.form.clearValidate('instance')},model:{value:(_vm.form.characteristic),callback:function ($$v) {_vm.$set(_vm.form, "characteristic", $$v)},expression:"form.characteristic"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-10px","right":"2px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.characteristic.length)+"/500")])])],1),_c('a-form-model-item',{attrs:{"label":"视频介绍","prop":"videoUrl"}},[_c('div',[_c('single-upload',{attrs:{"accept":['mp4'],"limit":1024 * 1024 * 200},on:{"success":_vm.handleVideoUrl}}),(_vm.form.videoUrl)?_c('a-row',{staticStyle:{"margin":"8px 0","line-height":"normal"},attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"},attrs:{"flex":"1"}},[_c('a',{staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"},attrs:{"target":"blank_"},on:{"click":function($event){return _vm.handlePreviewVideo(_vm.form.videoUrl)}}},[_c('a-icon',{staticStyle:{"margin-right":"8px"},attrs:{"type":"file"}}),_vm._v(_vm._s(_vm.form.videoUrl)+" ")],1)]),_c('a-col',{staticClass:"error-color",staticStyle:{"margin-left":"16px","cursor":"pointer"},on:{"click":function($event){_vm.form.videoUrl = ''}}},[_c('a-icon',{staticClass:"error-color",attrs:{"type":"delete"}})],1)],1):_vm._e(),_c('div',{staticClass:"disabled-color",staticStyle:{"line-height":"1.5"}},[_vm._v(" 提示：MP4格式文件，大小不超过200MB ")])],1)]),_c('a-form-model-item',{attrs:{"label":"购买须知：","prop":"purchaseInstructions","rules":{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":300,"autoSize":{ minRows: 3, maxRows: 3 },"type":"textarea"},on:{"change":() => _vm.$refs.form.clearValidate('instance')},model:{value:(_vm.form.purchaseInstructions),callback:function ($$v) {_vm.$set(_vm.form, "purchaseInstructions", $$v)},expression:"form.purchaseInstructions"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-10px","right":"2px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.purchaseInstructions.length)+"/300")])])],1),_c('a-form-model-item',{attrs:{"label":"商品选择："}},[_c('a-button',{staticStyle:{"width":"100px"},attrs:{"type":"primary","shape":"round"},on:{"click":function($event){_vm.showGoodsModel = true}}},[_vm._v("添加")]),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{staticClass:"error-color",staticStyle:{"margin-right":"4px"},attrs:{"theme":"filled","type":"exclamation-circle"}}),_vm._v("关联服务都为必填项，请确保关联后保存。 ")],1)],1),(_vm.form.combinationSpecificationsSet.length === 0)?[_c('a-col',{staticStyle:{"margin":"90px auto 90px auto"},attrs:{"type":"flex","align":"middle"}},[_c('a-row',[_c('a-avatar',{attrs:{"size":110,"shape":"square","src":"record.url"}})],1),_c('a-row',{staticStyle:{"margin-top":"20px"}},[_c('span',{staticClass:"add-tips"},[_vm._v("您还没添加任何服务商品")])])],1)]:_vm._e(),_c('GoodsTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.combinationSpecificationsSet.length !== 0),expression:"form.combinationSpecificationsSet.length !== 0"}],ref:"goods",on:{"change":_vm.onSpecificationChange}}),_c('a-form-model-item',{attrs:{"label":"原始售价：","prop":"originPrice","rules":{
        required: true,
        message: '原始售价不能为空',
        trigger: 'blur'
      }}},[_c('a-input-number',{attrs:{"disabled":"","min":0,"max":999999.99,"precision":2,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.originPrice),callback:function ($$v) {_vm.$set(_vm.form, "originPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.originPrice"}})],1),_c('a-form-model-item',{attrs:{"label":"折扣：","prop":"discount","rules":{
        required: true,
        message: '折扣不能为空',
        trigger: 'blur'
      }}},[_c('a-input-number',{attrs:{"min":0,"max":100,"formatter":value => `${value}%`,"parser":value => value.replace('%', '')},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.discount"}})],1),_c('a-form-model-item',{attrs:{"label":"实际售价：","prop":"sellPrice","rules":{
        required: true,
        message: '实际售价不能为空',
        trigger: 'blur'
      }}},[_c('a-input-number',{attrs:{"min":0,"max":999999.99,"precision":2,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.sellPrice),callback:function ($$v) {_vm.$set(_vm.form, "sellPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sellPrice"}})],1)],2),_c('GoodsModel',{attrs:{"show":_vm.showGoodsModel,"compareData":_vm.compareData},on:{"update:show":function($event){_vm.showGoodsModel=$event},"confirm":_vm.onGoodsConfirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }